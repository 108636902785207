import React from 'react';
import { Image } from 'react-native';

import Link from './Link';

export default () => (
  <Link
    style={{
     flex: 1,
      position: 'absolute',
      bottom: 10,
      top: -170,
      right: 1,
      padding: 8,
      flexDirection: 'row',
      alignItems: 'center',


    }}
  >
    <Image
      style={{ opacity: 0.5, userSelect: 'none', width: 54, height: 54 }}
      source={{
        uri: 'https://w7.pngwing.com/pngs/319/880/png-transparent-two-black-upward-arrows-art-logo-graphic-design-up-arrow-angle-web-design-text.png',
      }}
    />
  </Link>
);
