import React from 'react';
import { Image, Text, View } from 'react-native';

import Link from './Link';

export default () => (
  <Link
    url="https://www.cdchost.com.br/"
    style={{
      userSelect: 'none',
      textDecoration: 'none',
      position: 'absolute',
      bottom: 30,
      left: 8,
    }}
  >
    <View style={{ opacity: 0.5, flexDirection: 'row', alignItems: 'center' }}>

      <Image
        style={{ width: 190, userSelect: 'none', height: 36 }}
        source={{
          uri:
            'https://cdchost.com.br/portal/assets/img/logo.png',
        }}
      />

    </View>
  </Link>
);
